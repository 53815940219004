import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'


// styles
import "../styles/global.css";
import "../styles/contact.css";
import "@fontsource/sora/300.css";
import "@fontsource/sora/500.css";
import "@fontsource/sora/400.css";
import "@fontsource/sora/600.css";
import "@fontsource/sora";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/caveat";

const ContactPage = () => {

  return (
    <Layout pageTitle="contact">
     
      <div className="contentContact" >
        <h2>Get in touch</h2>
        
        <div className="flexH">
           <div >
              
              <p>Pop me an email at <br/>
                <a className='link mail' href="mailto:may.zedlav@gmail.com">may.zedlav@gmail.com</a>
              </p>
              
          </div>
          <StaticImage
          alt="Mayte avatar"
          src="../images/cool.png"
          placeholder="blurred"
          layout="constrained"
          width={150}
          height={150}
          />
          <p>I’d love to hear from you!</p>
        </div>
       <p className="bigViolet">Let’s work <span className="tanFont">together !</span></p>
        
        <div className="flexH">
          <a href="https://www.linkedin.com/in/valdezmayte/" rel="noreferrer" target="_blank"><button>Contact me on LinkedIn </button></a>
        </div>
        
        
      </div>
      
    </Layout>
  )
}

export default ContactPage